import {ReactElement} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";


const Archive2025 = (): ReactElement => {

  return (
      <div className={'siteContainer'}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={'headline'}>AUSSTELLUNGSPLAN NADLER GALERIE 2025</div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div>&nbsp;</div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className={'headline'}>18.02. Kunstwoche des Elsterschloss Gymnasium</div>
            </Col>
          </Row>
          <Row>
            <Col md={12} className={'infoText'}>Stadtrundgang „Kunstpfad“ 26.02.2025, 12.00 bis 15.30 Uhr
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Link to={'./silvio-kempf'} className={'externalLink'}><div className={'headline'}>28.02. - 11.05. SILVIO KEMPF - ABSTRACT PHOTO ART</div></Link>
            </Col>
          </Row>
          <Row>
            <Col md={12} className={'infoText'}>Vernissage 28.02.2025, 19.00 Uhr
            </Col>
          </Row>
          <Row>
            <Col md={12}>
             <div className={'headline'}>16.05. - 13.07. NINA & KATJA HELMS - PHOTO REALISTIC ART</div>
            </Col>
          </Row>
          <Row>
            <Col md={12} className={'infoText'}>Vernissage 16.05.2025, 19.00 Uhr
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className={'headline'}>18.07. - 14.09. GERHART LAMPA - KÜNSTLERNACHLASS LAUSITZ</div>
            </Col>
          </Row>
          <Row>
            <Col md={12} className={'infoText'}>Vernissage 18.07.2025, 19.00 Uhr
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className={'headline'}>19.09. - 16.11. SEBASTIAN ARSAND - SKULPTUR, MALEREI</div>
            </Col>
          </Row>
          <Row>
            <Col md={12} className={'infoText'}>Vernissage 19.09.2025, 19.00 Uhr
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className={'headline'}>21.11. - 25.01. SVEN GROSSKREUTZ - MALEREI, GRAFIK</div>
            </Col>
          </Row>
          <Row>
            <Col md={12} className={'infoText'}>Vernissage 21.11.2025, 19.00 Uhr
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div>&nbsp;</div>
            </Col>
          </Row>
        </Container>
      </div>
  )
}
export default Archive2025;