import {ReactElement} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";


const Archive2023 = (): ReactElement => {

  return (
      <div className={'siteContainer'}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={'headline'}>AUSSTELLUNGSPLAN NADLER GALERIE 2023</div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div>&nbsp;</div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className={'headline'}>11.01.-08.02. Elsterschloss Gymnasium</div>
            </Col>
          </Row>
          <Row>
            <Col md={12} className={'infoText'}>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Link to={'./evelin'} className={'externalLink'}><div className={'headline'}>11.02.-20.04. Evelin Waldmann</div></Link>
            </Col>
          </Row>
          <Row>
            <Col md={12} className={'infoText'}>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Link to={'./jens'} className={'externalLink'}><div className={'headline'}>23.04.-29.06. Jens Puppe</div></Link>
            </Col>
          </Row>
          <Row>
            <Col md={12} className={'infoText'}>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Link to={'./franziska'} className={'externalLink'}><div className={'headline'}>08.07.-07.09. Franziska Gußmann</div></Link>
            </Col>
          </Row>
          <Row>
            <Col md={12} className={'infoText'}>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Link to={'./uwe'} className={'externalLink'}><div className={'headline'}>ab 09.09. Uwe Schaffranitz</div></Link>
            </Col>
          </Row>
          <Row>
            <Col md={12} className={'infoText'}>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div>&nbsp;</div>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <div className={'headline'}>VERANSTALTUNGSPLAN NADLER GALERIE 2023</div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div>&nbsp;</div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className={'headline'}>09.03. 19:00 Uhr Hermann Hesse „Leben und Werk“</div>
            </Col>
          </Row>
          <Row>
            <Col md={12} className={'infoText'}>vorgestellt vom Dresdner Schauspieler Lars Jung</Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className={'headline'}>21.04. 19:00 Uhr Hermann Hesse Literarischer-musikalischer Abend</div>
            </Col>
          </Row>
          <Row>
            <Col md={12} className={'infoText'}>mit Schauspieler Lars Jung und Gitarrist Thomas Scholz</Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className={'headline'}>26.08. 19:00 Uhr Festakt zum 40jährigen Bestehen der Nadler Galerie</div>
            </Col>
          </Row>
          <Row>
            <Col md={12} className={'infoText'}></Col>
          </Row>
        </Container>
      </div>
  )
}
export default Archive2023;