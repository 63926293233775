import React, {ReactElement} from 'react';
import {Container, Nav, Navbar} from 'react-bootstrap';


const MainMenu = (): ReactElement => {
  return (
    <Navbar  collapseOnSelect expand="lg" bg="light" variant="light" sticky="top">
      <Container className={'menuContainer'}>
        <Navbar.Brand className={"navbar-links"} href="/events">NADLERGALERIE</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto" >
            <Nav.Link href="/2025" className={"navbar-links"}>2025</Nav.Link>
            <Nav.Link href="/archive" className={"navbar-links"}>Archiv</Nav.Link>

            {/*<Nav.Link>MAIN + {currentBreakpoint}</Nav.Link>*/}
            {/*<NavDropdown title="Information" id="collasible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Datenschutz</NavDropdown.Item>
              <NavDropdown.Divider/>
              <NavDropdown.Item href="#action/3.2">Impressum</NavDropdown.Item>
            </NavDropdown>*/}
          </Nav>
          <Nav>

            <Nav.Link className={"navbar-links"} href="/contact">Kontakt</Nav.Link>
            <Nav.Link className={"navbar-links"} href="/legal">Datenschutz</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
export default MainMenu;
