import React, {FunctionComponent, ReactElement} from "react";
import {Route, Routes} from "react-router-dom";
import Events2025 from "./Archive/Archive2025";
import {EvelinWaldmann} from "./Archive/Artists/2023/EvelinWaldmann";
import {JensPuppe} from "./Archive/Artists/2023/JensPuppe";
import {FranziskaGussmann} from "./Archive/Artists/2023/FranziskaGussmann";
import {UweSchaffranietz} from "./Archive/Artists/2023/UweSchaffranietz";
import {SilvioKempf} from "./Archive/Artists/2025/SilvioKempf";


const EventList: FunctionComponent = (): ReactElement => {
  return (
      <Routes>
        <Route path={"silvio-kempf"} element={<SilvioKempf/>}/>
        <Route path={"evelin"} element={<EvelinWaldmann/>}/>
        <Route path={"jens"} element={<JensPuppe/>}/>
        <Route path={"franziska"} element={<FranziskaGussmann/>}/>
        <Route path={"uwe"} element={<UweSchaffranietz/>}/>
        <Route path={"*"} element={<Events2025/>}/>
      </Routes>
  );
}
export default EventList;
