import {FunctionComponent, ReactElement} from "react";
import 'react-photoswipe/lib/photoswipe.css';
import {GudrunBroechlerNeumann} from "./Archive/Artists/2024/GudrunBroechlerNeumann";
import {SilvioKempf} from "./Archive/Artists/2025/SilvioKempf";


const Event: FunctionComponent = (): ReactElement => {
  return (
      <SilvioKempf/>
    /*<GudrunBroechlerNeumann/>*/
  );
}
export default Event;
