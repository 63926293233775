import {ReactElement} from "react";
import {Col, Container, Row} from "react-bootstrap";


const Contact = (): ReactElement => {

  return (
    <>
      <div className={'siteContainer'}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={'headline'}>Die Kleine Galerie Hans Nadler ist für Sie geöffnet</div>
            </Col>
          </Row>
          <Row>
            <Col md={12} className={'infoText'}>
              {/*<div>Donnerstag bis Sonntag 14 - 17 Uhr</div>*/}
              <div>Auf Anfrage</div>
              <div>Montag bis Donnerstag geschlossen</div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className={'headline'} style={{marginTop: '2rem'}}>Kontakt / Bewerbung</div>
            </Col>
          </Row>
          <Row>
            <Col md={12} className={'infoText'}>
              <div>Mail: info@nadlergalerie.de</div>
              <div className={'infoText'}>
                <div>Nadler Galerie</div>
                <div>Hauptstraße 29</div>
                <div>Elsterwerda, Brandenburg 04924 Deutschland</div>
                <br/>
                <div>Bitte senden Sie uns Ihre aussagekräftige Bewerbung als E-Mail (Bildmaterial / Webseite) zu.</div>
                <div>Bei Interesse melden wir uns innerhalb von zwei Wochen zurück.</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className={'headline'}></div>
            </Col>
          </Row>
          <Row>
            <Col md={12} className={'infoText'}>
              <div>Nadler Galerie</div>
              <div>Sebastian Pöschl (Galerieleiter)</div>
              <div>gehört zu</div>
              <div>Heimatverein Elsterwerda und Umgebung e.V.</div>
              <div>Lerchenweg 3</div>
              <div>Elsterwerda, Brandenburg 04910 Deutschland</div>
              {/*<div className={"additionalInfos"}>Im Internet:<br/>
                <a href={"https://www.heimatverein-elsterwerda.de"} className={'externalLink'}>www.heimatverein-elsterwerda.de</a>
              </div>*/}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className={'headline'} style={{marginTop: '2rem'}}>Unsere Sponsoren</div>
            </Col>
          </Row>
          <Row>
            <Col md={12} className={'infoText'}>
              <ul>
                <li>Heimatverein Elsterwerda und Umgebung e.V.</li>
                <li>Sparkasse Elbe-Elster</li>
                <li>Stadt Elsterwerda</li>
                <li>Landkreis Elbe-Elster</li>
                <li>Hesse Werbung</li>
              </ul>
              <div style={{marginTop: '1rem'}}>
                Die Nadler Galerie ist auf Sponsorengelder angewiesen!
                Ohne die finanzielle Unterstützung von Firmen oder Privatpersonen kann die Galerie nicht existieren.
                Wenn auch Sie einen Beitrag für die Kunst- und Kulturlandschaft im Landkreis Elbe-Elster leisten möchten
                und die Nadler Galerie in Elsterwerda unterstützen wollen, helfen Sie uns mit Ihrer Spende und nehmen Sie Kontakt mit uns auf.
              </div>

            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
export default Contact;