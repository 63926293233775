import {Container, Nav, Navbar} from "react-bootstrap";
import React, {useContext} from "react";
import {WindowBreakpointContext} from "../types/context/Context";
import {WindowBreakpoint} from "../types/context/WindowBreakpoint";

const MobileMenu = () => {
  const currentBreakpoint = useContext(WindowBreakpointContext);
  const containerClass = !(currentBreakpoint === WindowBreakpoint.MD
                           || currentBreakpoint === WindowBreakpoint.XS
                           || currentBreakpoint === WindowBreakpoint.SM
  ) ? 'd-none' : 'd-block';

  return (

      <Container className={'' + containerClass}>
        <hr/>
        <Navbar collapseOnSelect bg="light" variant="light">
          <Nav className="me-auto">
            <Nav.Link href="/2025" className={"navbar-links"}>2023</Nav.Link>
            <Nav.Link href="/archive" className={"navbar-links"}>Archiv</Nav.Link>
            <Nav.Link className={"navbar-links"} href="/contact">Kontakt</Nav.Link>
            <Nav.Link className={"navbar-links"} href="/legal">Datenschutz</Nav.Link>
          </Nav>
        </Navbar>
      </Container>

  );
};

export default MobileMenu;
