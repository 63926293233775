import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import WindowBreakpointUtils, {WindowBreakpoint} from './types/context/WindowBreakpoint';

import './App.css';
import {WindowBreakpointContext} from "./types/context/Context";
import MainMenu from "./Sites/MainMenu";
import EventPage from "./Sites/Content/Event";
import Location from "./Sites/Content/Location";
import Legal from "./Sites/Content/Legal";
import Contact from "./Sites/Content/Contact";
import {Container} from "react-bootstrap";
import MobileMenu from "./Sites/MobileMenu";
import EventList from "./Sites/Content/EventList";
import {ArchiveList} from "./Sites/Content/Archive/ArchiveList";


const App = () => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState<WindowBreakpoint>(WindowBreakpointUtils.calculateCurrentWindowBreakpoint()
  );
  useEffect(() => {
    const updateCurrentBreakpoint = () => {
      setCurrentBreakpoint(WindowBreakpointUtils.calculateCurrentWindowBreakpoint());
    };
    window.addEventListener('resize', updateCurrentBreakpoint);
    return (() => window.removeEventListener('resize', updateCurrentBreakpoint));
  }, []);

  return (
    <WindowBreakpointContext.Provider value={currentBreakpoint}>
      <MainMenu/>
      <main>
        <Container>
          <Router>
            <Routes>
              <Route path="/2025/*" element={<EventList/>}/>
              <Route path="/archive/*" element={<ArchiveList/>}/>
              <Route path="/open" element={<Location/>}/>
              <Route path="/contact" element={<Contact/>}/>
              <Route path="/legal" element={<Legal/>}/>
              <Route path={"*"} element={<EventPage/>}/>
            </Routes>
          </Router>
        </Container>
      </main>
      <MobileMenu/>
      {/*<div className="ocean">
          <div className="wave"></div>
          <div className="wave"></div>
        </div>*/}
    </WindowBreakpointContext.Provider>
  );
}

export default App;
