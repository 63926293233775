import {FunctionComponent, ReactElement} from "react";
import Archive2022 from "./Archive2022";
import Archive2023 from "./Archive2023";

export const Archive: FunctionComponent = (): ReactElement => {
  return (
    <>

      <br/>
      <Archive2023/>
      <hr/>
      <Archive2022/>
    </>
  )
}