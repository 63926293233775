import {Col, Container, Image, Row} from "react-bootstrap";
import {PhotoSwipeGallery} from "react-photoswipe";
import {AppUrl} from "../../../../../types/AppUrl";

export const SilvioKempf = () => {

  let items: any[] = [
    {
      src: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/1/17.jpg',
      thumbnail: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/1/thumbnail/17.jpg',
      w: 1250,
      h: 1250,
      title: '<div class="imageCaption fadeIn"><div>#17 Winter trees upside-down (2020)</div><div>Foto: © Silvio Kempf</div> </div>'
    },
    {
      src: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/1/18.jpg',
      thumbnail: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/1/thumbnail/18.jpg',
      w: 1250,
      h: 1250,
      title: '<div class="imageCaption fadeIn"><div>#18 Winterlandschaft (2021)</div><div>Foto: © Silvio Kempf</div></div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/2/52.jpg',
      thumbnail: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/2/thumbnail/52.jpg',
      w: 1250,
      h: 1250,
      title: '<div class="imageCaption fadeIn"><div>#52 Yellow wooden illusion (2023)</div><div>Foto: © Silvio Kempf</div></div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/2/59.jpg',
      thumbnail: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/2/thumbnail/59.jpg',
      w: 1250,
      h: 1250,
      title: '<div class="imageCaption fadeIn"><div>#59 Into the woods (2024)</div><div>Foto: © Silvio Kempf</div></div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/2/67.jpg',
      thumbnail: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/2/thumbnail/67.jpg',
      w: 1250,
      h: 1250,
      title: '<div class="imageCaption fadeIn"><div>#67 Into the woods (2024)</div><div>Foto: © Silvio Kempf</div></div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/2/illusion_130124.jpg',
      thumbnail: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/2/thumbnail/illusion_130124.jpg',
      w: 1250,
      h: 1250,
      title: '<div class="imageCaption fadeIn"><div>#60 Into the woods (2024)</div><div>Foto: © Silvio Kempf</div></div>'
    }/*, {
      src: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/3/54.jpg',
      thumbnail: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/3/thumbnail/54.jpg',
      w: 1250,
      h: 1250,
      title: '<div class="imageCaption fadeIn"><div>#54 blue and sand illusion (2023)</div><div>Foto: © Silvio Kempf</div></div>'
    }*/, {
      src: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/3/62.jpg',
      thumbnail: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/3/thumbnail/62.jpg',
      w: 1250,
      h: 1250,
      title: '<div class="imageCaption fadeIn"><div>#62 (2024)</div><div>Foto: © Silvio Kempf</div></div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/3/63.jpg',
      thumbnail: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/3/thumbnail/63.jpg',
      w: 1250,
      h: 1250,
      title: '<div class="imageCaption fadeIn"><div>#63 Brunnen bei Staatsgalerie (2024)</div><div>Foto: © Silvio Kempf</div></div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/3/68.jpg',
      thumbnail: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/3/thumbnail/68.jpg',
      w: 1250,
      h: 1250,
      title: '<div class="imageCaption fadeIn"><div>#68 (2024)</div><div>Foto: © Silvio Kempf</div></div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/4/38.jpg',
      thumbnail: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/4/thumbnail/38.jpg',
      w: 1250,
      h: 1250,
      title: '<div class="imageCaption fadeIn"><div>#38 butter y patches (2023)</div><div>Foto: © Silvio Kempf</div></div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/4/41.jpg',
      thumbnail: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/4/thumbnail/41.jpg',
      w: 1250,
      h: 1250,
      title: '<div class="imageCaption fadeIn"><div>#41 green white patches (2023)</div><div>Foto: © Silvio Kempf</div></div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/5/1.jpg',
      thumbnail: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/5/thumbnail/1.jpg',
      w: 937,
      h: 1250,
      title: '<div class="imageCaption fadeIn"><div>#69 All plants in full splendor No.1 (2025)</div><div>Foto: © Silvio Kempf</div></div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/5/2.jpg',
      thumbnail: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/5/thumbnail/2.jpg',
      w: 937,
      h: 1250,
      title: '<div class="imageCaption fadeIn"><div>#70 All plants in full splendor No.2 (2025)</div><div>Foto: © Silvio Kempf</div></div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/5/3.jpg',
      thumbnail: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/5/thumbnail/3.jpg',
      w: 937,
      h: 1250,
      title: '<div class="imageCaption fadeIn"><div>#71 All plants in full splendor No.3 (2025)</div><div>Foto: © Silvio Kempf</div></div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/5/4.jpg',
      thumbnail: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/5/thumbnail/4.jpg',
      w: 937,
      h: 1250,
      title: '<div class="imageCaption fadeIn"><div>All plants in full splendor No.4 (2025)</div><div>Foto: © Silvio Kempf</div></div>'
    }/*,{
      src: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/6/30.jpg',
      thumbnail: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/6/thumbnail/30.jpg',
      w: 1250,
      h: 1250,
      title: '<div class="imageCaption fadeIn"><div>#30 abstract illusion in warm yellow (2023)</div><div>Foto: © Silvio Kempf</div></div>'
    }*/, {
      src: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/6/37.jpg',
      thumbnail: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/6/thumbnail/37.jpg',
      w: 1250,
      h: 1250,
      title: '<div class="imageCaption fadeIn"><div>#37 golden strips on black (2023)</div><div>Foto: © Silvio Kempf</div></div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/6/44.jpg',
      thumbnail: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/6/thumbnail/44.jpg',
      w: 1250,
      h: 1250,
      title: '<div class="imageCaption fadeIn"><div>#44 abstract illusion blue and creme sensation (2023)</div><div>Foto: © Silvio Kempf</div></div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/7/20.jpg',
      thumbnail: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/7/thumbnail/20.jpg',
      w: 1250,
      h: 1250,
      title: '<div class="imageCaption fadeIn"><div>#20 (2021)</div><div>Foto: © Silvio Kempf</div></div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/7/30.jpg',
      thumbnail: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/7/thumbnail/30.jpg',
      w: 1250,
      h: 1250,
      title: '<div class="imageCaption fadeIn"><div>#30 abstract illusion in warm yellow (2023)</div><div>Foto: © Silvio Kempf</div></div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/8/6.jpg',
      thumbnail: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/8/thumbnail/6.jpg',
      w: 1250,
      h: 1250,
      title: '<div class="imageCaption fadeIn"><div>#6 nature mirror (2020)</div><div>Foto: © Silvio Kempf</div></div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/8/13.jpg',
      thumbnail: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/8/thumbnail/13.jpg',
      w: 1250,
      h: 1250,
      title: '<div class="imageCaption fadeIn"><div>#13 shadow wood (2020)</div><div>Foto: © Silvio Kempf</div></div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/8/14.jpg',
      thumbnail: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/8/thumbnail/14.jpg',
      w: 1250,
      h: 1250,
      title: '<div class="imageCaption fadeIn"><div>#14 shadow play (2020)</div><div>Foto: © Silvio Kempf</div></div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/9/15.jpg',
      thumbnail: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/9/thumbnail/15.jpg',
      w: 1250,
      h: 1250,
      title: '<div class="imageCaption fadeIn"><div>#15 sedimentary rock (2020)</div><div>Foto: © Silvio Kempf</div></div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/9/45.jpg',
      thumbnail: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/9/thumbnail/45.jpg',
      w: 1250,
      h: 1250,
      title: '<div class="imageCaption fadeIn"><div>#45 abstract illusion purple black unsharpen (2023)</div><div>Foto: © Silvio Kempf</div></div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/9/54.jpg',
      thumbnail: AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/9/thumbnail/54.jpg',
      w: 1250,
      h: 1250,
      title: '<div class="imageCaption fadeIn"><div>#54 blue and sand illusion (2023)</div><div>Foto: © Silvio Kempf</div></div>'
    },
  ];

  let options = {
    history: false,
    focus: false,
    showAnimationDuration: 600,
    hideAnimationDuration: 600,
    closeOnScroll: false,
    shareEl: false,
    fullscreenEl: false,
    addCaptionHTMLFn: function (item: any, captionEl: any, isFake: boolean) {
      // item      - slide object
      // captionEl - caption DOM element
      // isFake    - true when content is added to fake caption container
      //             (used to get size of next or previous caption)

      if (!item.title) {
        captionEl.children[0].innerHTML = '';
        return false;
      }
      captionEl.children[0].innerHTML = item.title;
      return true;
    }
  };

  const getThumbnailContent = (item: any) => {
    return (
        <img src={item.thumbnail} alt={item.title} style={{margin: '3px'}}/>
    );
  }

  return (
      <div className={'siteContainer'}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={'eventPeriod'}>28.02.2025 - 11.05.2025, Vernissage 28.02.2025 19:00 Uhr</div>
              <div className={'eventPeriod'}>licht.illusion / Abstract Photo Art</div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Image src={AppUrl.getBaseUrl() + '/img/2025/silvio_kempf/Portrait.jpg'}
                     className={'artistImage '}
                     alt={'Silvio Kempf Portrait'}
              />
              <div className={'artistImageText'}>© Silvio Kempf</div>
            </Col>
            <Col md={8}>
              <div className={'artistName'}>Silvio Kempf</div>
              <div className={'artistBio'}>
                <div>
                  Silvio Kempf (geb. Herrmann) wurde 1978 in Elsterwerda, Deutschland, geboren. Er lebt und arbeitet
                  heute in Stuttgart (Baden-Württemberg). Kempf hat einen professionellen Hintergrund in der Filmbranche
                  und ist fotografischer Autodidakt.
                  <br/><br/>
                  Die unter dem Label licht.illusion präsentierten abstrakten Werke des Künstlers lassen die Grenzen
                  zwischen Realität und Illusion verschwimmen. Farben, Texturen und Formen in außergewöhnlichen
                  Kombinationen bringen Neues zum Vorschein. Die Fotografien, oftmals Naturmotive und Alltägliches,
                  verwandeln sich in faszinierende Welten, die unsere Wahrnehmung der Realität in Frage stellen. Abseits
                  bloßer Dokumentation unserer unmittelbaren Umgebung entsteht Kunst, die mehr als nur das Sichtbare
                  einfängt. Abstraktion in den Motiven entsteht bereits bei der Wahl von ungewöhnlichen Bildausschnitten
                  und Details, welche der Künstler in den Fokus nimmt. Bewusst gesetzte Unschärfen lassen die Grenzen
                  zwischen Fotografie und Malerei verschwimmen, was seine Werke einzigartig macht.
                  <br/><br/>
                  Im Jahr 2024 wurden zahlreiche limitierte Artworks bei Einzel- und Gruppenausstellungen gezeigt. Als
                  sehr erfolgreich stellte sich im November 2024 die Zusammenarbeit mit der Strzelski Galerie Stuttgart
                  heraus. Im Rahmen der Galerie "Pop Up Dillmann" wurden während der dreitägigen Gruppenausstellung neue
                  Werke von licht.illusion gezeigt und verkauft.
                  <br/>
                  <br/>
                  Ab Februar 2025 wird in der Nadler Galerie Elsterwerda (Brandenburg) eine Einzelausstellung des
                  Künstlers zu sehen sein. Hier wird zum ersten Mal ein Vorgeschmack auf die neue Serie „All plants in
                  full splendor“ präsentiert. Darüber hinaus sind in diesem Jahr Ausstellungsbeiträge in Berlin,
                  Nürnberg, Heilbronn und Stuttgart vorgesehen.
                  <br/><br/>
                  <b>bisherige Ausstellungen:</b>
                  <ul>
                    <li>Villa Mertz - Heilbronn</li>
                    <li>Silberblick - Stuttgart</li>
                    <li>VEGI - Stuttgart</li>
                    <li>„Pop-up Dillmann“ Gruppenausstellung</li>
                  </ul>

                </div>
              </div>
              {/* <div className={"additionalInfos"}>weitere Informationen:<br/>

                <a href={"https://private-kuenstlernachlaesse-brandenburg.de/person/9"}
                   className={'externalLink'}>private-kuenstlernachlaesse-brandenburg.de</a>
              </div>*/}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <PhotoSwipeGallery items={items} options={options} thumbnailContent={getThumbnailContent}/>
            </Col>
          </Row>
        </Container>
      </div>
  )
}
